/* eslint-disable max-lines */
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { AchievementsService } from '../core-services/achievements/achievements.service';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { GamificationWidgetService } from './gamification-widget.service';
import { widgetConfig, WidgetConfigInterface } from './gamification-widget.config';
import { WidgetConfig, WidgetItemTypeEnum, WidgetItem, WidgetTypeEnum } from './gamification-widget.model';
import { Achievement, Metadata } from '../core-services/achievements/achievements.model';
import { PlayService } from '../core-services/play/play.service';
import { BaseComponent } from '../base.component';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { GameModeEnum } from '../core-services/play/play.model';
import { TournamentsService } from '../core-services/tournaments/tournaments.service';
import { Competition, OptInRequest } from '../core-services/tournaments/tournaments.model';
import { UserStore } from '../core-services/user/user.store';
import { InfoModalService } from '../info-modal/info-modal.service';
import { InfoModal } from '../info-modal/info-modal.model';
import { PromotionEnum } from '../promotions-today/promotions-today.model';
import { CoreService } from '../core-services/core.service';
import { CurrencyConverter } from './currency-converter';
import { CountryCodesType } from './country-currency-codes';
import { UserDetails } from '../core-services/user/user.model';

export const GAMIFICATION_WIDGET_SELECTOR: string = 'whg-gamification-widget';

@Component({
  selector: GAMIFICATION_WIDGET_SELECTOR,
  templateUrl: './gamification-widget.component.html',
  styleUrls: ['./gamification-widget.component.scss'],
})
export class GamificationWidgetComponent extends BaseComponent implements OnInit {
  public widgetConfig: WidgetConfigInterface[] = widgetConfig;
  public activeItemType!: WidgetItemTypeEnum;
  public isLoading: boolean = true;
  public challenges!: WidgetConfig;
  public tournaments!: WidgetConfig;
  public isLoggedIn: boolean = false;
  public userId!: number | undefined;
  public isOpen: boolean = true;
  public tournamentId!: string;
  public challengeId!: string;
  public currencySymbol?: string;
  private modalRef?: NgbModalRef;

  constructor(
    private achievementsService: AchievementsService,
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private infoModalService: InfoModalService,
    private playService: PlayService,
    private tournamentsService: TournamentsService,
    private userStore: UserStore,
    private visitorStore: VisitorStore,
    private widgetService: GamificationWidgetService,
    private ngbModal: NgbModal,
    private coreService: CoreService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subscribeToggle();
    this.subscribeUser();
    this.subscribeToCurrency();
  }

  public openModal(config: InfoModal): void {
    const resolver = (): void => {
      this.modalRef = undefined;
      this.infoModalService.setConfig(undefined);
    };

    this.infoModalService.setConfig(config);

    this.modalRef = this.ngbModal.open(InfoModalComponent, { windowClass: 'whg-info-modal--open' });
    this.modalRef.result.then(resolver, resolver);
  }

  public setActiveType(type: string): void {
    this.activeItemType = type as WidgetItemTypeEnum;
    this.isLoading = true;

    switch (this.activeItemType) {
      case WidgetItemTypeEnum.challenges:
        this.setChallenges();
        this.tournamentId = '';
        break;

      case WidgetItemTypeEnum.tournaments:
        this.setTournaments();
        this.challengeId = '';
        break;
    }
  }

  public openWidget(type: WidgetItemTypeEnum, id?: string | null): void {
    if (type === WidgetItemTypeEnum.tournaments) {
      this.tournamentId = id ?? '';
    }
    if (type === WidgetItemTypeEnum.challenges) {
      this.challengeId = id ?? '';
    }

    this.setActiveType(type);

    if (this.elementRef.nativeElement.classList.contains('hide')) {
      this.elementRef.nativeElement.classList.remove('hide');
    }
    if (!this.document.body.classList.contains('modal-open')) {
      this.document.body.classList.add('modal-open');
    }

    this.isOpen = true;
  }

  public closeWidget(): void {
    if (this.document.body.classList.contains('modal-open')) {
      this.document.body.classList.remove('modal-open');
      this.elementRef.nativeElement.classList.add('hide');
    }
    this.isOpen = false;
  }

  private setChallenges(): void {
    // eslint-disable-next-line @typescript-eslint/typedef
    this.achievementsService.getAchievements().subscribe(({ data }) => {
      const challengesValidPromo: Achievement[] = data.filter((challenge: Achievement) =>
        Object.values(PromotionEnum).includes(challenge.metadata.find((meta: Metadata) => meta.key === 'promo')?.value ?? ('' as any))
      );

      const todayChallenges: Achievement[] = challengesValidPromo.filter((challenge: Achievement) => {
        // eslint-disable-next-line @typescript-eslint/typedef
        const { startDate, endDate } = challenge.scheduling;
        const today: moment.Moment = moment();
        const start: moment.Moment = moment(startDate).startOf('day');
        const end: moment.Moment = moment(endDate).endOf('day');

        return today.isBetween(start, end);
      });

      this.challenges = {
        type: WidgetTypeEnum.list,
        items: this.parseChallenges(todayChallenges),
        itemType: WidgetItemTypeEnum.challenges,
      };
      this.isLoading = false;
    });
  }

  private setTournaments(): void {
    // eslint-disable-next-line @typescript-eslint/typedef
    this.tournamentsService.getCompetitions().subscribe(({ data }) => {
      const competitionsValidPromo: Competition[] = data.filter((competition: Competition) =>
        Object.values(PromotionEnum).includes(competition.metadata.promo)
      );
      const todayCompetitions: Competition[] = competitionsValidPromo.filter((competition: Competition) => {
        const { scheduledStart, scheduledEnd }: Competition = competition;
        const today: moment.Moment = moment();
        const start: moment.Moment = moment(scheduledStart).startOf('day');
        const end: moment.Moment = moment(scheduledEnd).endOf('day');

        return today.isBetween(start, end);
      });

      this.tournaments = {
        type: WidgetTypeEnum.list,
        items: this.parseTournaments(todayCompetitions),
        itemType: WidgetItemTypeEnum.tournaments,
      };
      this.isLoading = false;
    });
  }

  private parseTournaments(tournaments: Competition[]): WidgetItem[] {
    return tournaments.map((tournament: Competition, index: number) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { id, label, description, scheduledEnd, scheduledStart, optinRequired }: Competition = tournament;
      let hasOptIn: boolean = false;
      const tmpElement: HTMLElement = document.createElement('div');

      tmpElement.innerHTML = description;
      const img: HTMLImageElement | null = tmpElement.querySelector('img');

      if (this.userId && optinRequired) {
        this.tournamentsService.getOptedInUsers(this.userId, tournament.id).subscribe(
          ({ data: { memberRefId } }: OptInRequest) => {
            hasOptIn = memberRefId === (this.userId && this.userId.toString());
            this.tournaments.items[index].optinRequired = hasOptIn ? false : optinRequired;
          },
          // eslint-disable-next-line @typescript-eslint/typedef
          (err) => console.log('err', err)
        );
      }

      const sigTerms: string = `sigterms_${this.coreService.getCurrentLanguage()}`;

      return {
        id,
        title: label,
        description: this.cleanDescription(description),
        shortDescription: this.setShortDescription(tmpElement),
        image: img ? (img.getAttribute('src') as string) : '',
        remainingTime: this.setRemainingTime(scheduledEnd),
        terms: tournament.termsConditions,
        sigTerms: tournament.metadata[sigTerms] ?? '',
        startDate: scheduledStart,
        endDate: scheduledEnd,
        optinRequired,
      };
    });
  }

  private parseChallenges(challenges: Achievement[]): WidgetItem[] {
    return challenges.map((challenge: Achievement) => {
      const { id, name, description, metadata, scheduling, dependantOn, scheduledEnd }: Achievement = challenge;
      let game: Metadata | undefined;
      let terms: Metadata | undefined;
      const tmpElement: HTMLElement = document.createElement('div');

      tmpElement.innerHTML = description;
      const img: HTMLElement | null = tmpElement.querySelector('img');

      if (metadata) {
        game = this.getMetaItem(metadata, 'game');
        terms = this.getMetaItem(metadata, 'terms_en');
      }
      const sigTerms: string = `sigterms_${this.coreService.getCurrentLanguage()}`;

      return {
        id,
        title: name,
        description: this.cleanDescription(description),
        shortDescription: this.setShortDescription(tmpElement),
        image: img ? (img.getAttribute('src') as string) : '',
        remainingTime: this.setRemainingTime(scheduling?.endDate || scheduledEnd),
        terms: terms ? `?page=${terms.value}` : '',
        sigTerms: challenge.metadata?.find((meta: Metadata) => meta.key === sigTerms)?.value ?? '',
        game: game ? this.playService.getGameUrl(game.value, this.isLoggedIn, GameModeEnum.Real) : undefined,
        dependantOn,
      };
    });
  }

  private setRemainingTime(endDate: Date): string {
    const now: moment.Moment = moment();
    const endMomentDate: moment.Moment = moment(endDate);

    const days: number = endMomentDate.diff(now, 'days');
    const hours: string = moment.utc(endMomentDate.diff(now)).format('HH');
    const minutes: string = moment.utc(endMomentDate.diff(now)).format('mm');

    return `${days}d ${hours}h ${minutes}m`;
  }

  private setShortDescription(element: HTMLElement): string {
    let description: string = '';

    Array.from(element.getElementsByTagName('p')).forEach((el: HTMLParagraphElement) => {
      if (el.innerText.trim() !== '' && description === '') {
        description = this.getShortDescription(el);
      }
    });

    return description;
  }

  private getShortDescription(element: HTMLParagraphElement): string {
    const ZERO: number = 0;
    const ONE_HUNDRED: number = 100;
    const MAX_LENGTH: number = 104;
    const text: string = element.innerText;

    return text.length > MAX_LENGTH ? `${text.substring(ZERO, ONE_HUNDRED)} ...` : text;
  }

  private subscribeUser(): void {
    this.visitorStore.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((isLoggedIn: boolean) => {
      this.isLoggedIn = isLoggedIn;

      if (isLoggedIn) {
        this.setUserId();
      }
    });
  }

  private subscribeToCurrency(): void {
    const ONE: number = 1;

    this.visitorStore.currencySymbol$
      .pipe(take(ONE))
      .subscribe((currencySymbol: string | undefined) => (this.currencySymbol = currencySymbol));
  }

  private setUserId(): void {
    this.userStore.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe((details: UserDetails | undefined) => (this.userId = details?.userid));
  }

  private getMetaItem(metadata: Metadata[], key: string): Metadata | undefined {
    return metadata.find((meta: Metadata) => meta.key === key);
  }

  private subscribeToggle(): void {
    this.widgetService.toggle.subscribe(({ open, type, id }: { open: boolean; type: WidgetItemTypeEnum | null; id: string | null }) => {
      if (!open || !type) {
        return;
      }

      return id ? this.openWidget(type, id) : this.openWidget(type);
    });
  }

  private cleanDescription(description: string): string {
    // Remove IMG inside P
    // eslint-disable-next-line no-param-reassign
    description = description.replace(/<p><img (.*?)><br><\/p>/g, '');
    // Create Temp HTML Element to remove empty nested tags
    const div: HTMLDivElement = document.createElement('div');

    div.innerHTML = description;
    this.removeEmptyNestedTags(div);
    this.applyCurrencySymbolConversion(div);
    // return only clean html content

    return div.innerHTML;
  }

  private removeEmptyNestedTags(element: Element): void {
    if (element.children.length) {
      // eslint-disable-next-line no-magic-numbers
      for (let i: number = 0; i < element.children.length; i++) {
        this.removeEmptyNestedTags(element.children[i]);
      }
      element.querySelector(':empty')?.remove();
    }
    element.querySelector(':empty')?.remove();
  }

  private applyCurrencySymbolConversion(element: Element): void {
    const currencyBoxes: NodeListOf<Element> = element.querySelectorAll('.currency-box');
    const ZERO: number = 0;
    const ONE: number = 1;

    if (currencyBoxes?.length && this.currencySymbol) {
      combineLatest([this.visitorStore.countryCode$, this.visitorStore.loggedIn$])
        .pipe(take(ONE))
        .subscribe(([countryCode, loggedIn]: [string, boolean]) => {
          currencyBoxes.forEach((box: Element) => {
            const amount: number = Number(box.querySelector('.amount')?.innerHTML ?? ZERO);
            const conversion: string | null = CurrencyConverter.to(amount, countryCode as CountryCodesType, 'symbol', 'en-US', loggedIn);

            box.innerHTML = conversion ?? box.innerHTML;
          });
        });
    }
  }
}
