import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UserStore } from '../core-services/user/user.store';
import { CoreService } from '../core-services/core.service';
import { filter, take, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base.component';
import { UserBalances } from '../core-services/user/user.model';
import { VisitorStore } from '../core-services/visitor/visitor.store';

export const STATUS_BAR_SELECTOR = 'whg-status-bar';

@Component({
  selector: STATUS_BAR_SELECTOR,
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
})
export class StatusBarComponent extends BaseComponent implements OnInit {
  balance?: UserBalances;
  currencyCode?: string;
  private sessionId = '';
  constructor(
    private userStore: UserStore,
    private coreService: CoreService,
    private visitorStore: VisitorStore,
    private http: HttpClient
  ) {
    super();
  }

  async ngOnInit() {
    this.sessionId = this.coreService.getSessionIdFromCookie();
    if (await this.isSessionIdValid()) {
      this.getBalance();
      this.getCurrency();
    }
  }

  getBalance() {
    this.userStore.startBalancePolling(this.coreService.getSessionIdFromCookie());
    this.userStore.userBalance$
      .pipe(
        takeUntil(this.destroy$),
        filter(x => !!x)
      )
      .subscribe(res => {
        if (res) {
          this.balance = res.balances;
        }
      });
  }
  getCurrency() {
    this.visitorStore.currencySymbol$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.currencyCode = res;
    });
  }

  /**
   * Check if sessionId is valid
   */
  private async isSessionIdValid(): Promise<boolean> {
    const { type } = await this.http
      .post<any>(`${this.coreService.platformBaseUrl.slice(0, -1)}`, {
        type: 'auth/validate',
        sessionid: this.sessionId,
      })
      .pipe(take(1))
      .toPromise();
    if (type === 'error') {
      return false;
    }
    return true;
  }
}
