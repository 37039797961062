import { BrandConfig } from '@whitehatgaming/shared';
import { logoConfig } from './../../projects/web-components/src/app/header/header.config';
import { countries, translations } from './translations';

export const brandConfig: BrandConfig = {
  id: 119,
  name: 'vegasloungecasino',
  displayName: 'vegasloungecasino',
  path: 'vegasloungecasino',
  translations: {
    translations: translations,
    countries: countries,
  },
  supportEmail: 'support@vegasloungecasino.com',
  languages: ['en', 'de', 'fi', 'no', 'es', 'fr', 'pt'],
  platformBranch: false,
  googleAnalyticsCode: '',
  googleAdwordsTagManagerCode: '',
  loqateKey: 'RM92-ZR56-MD29-MR16',
  logoUrl: logoConfig.img,
  customization: {
    playPageConfig: {
      logoDisplayed: true,
      accountDisplayed: true,
      miniCashierEnabled: true,
      quickDepositEnabled: true,
    },
  },
  optimove: {
    optimoveSDKToken: '450013853db6463891c6b71580f21152',
    optimoveSDKVersion: '2.0',
    optimoveSDKconfigVersion: 'web-configuration.1.0.0',
  },
  routesRedirectedToLegacy: ['logout'],
};
