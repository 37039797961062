import { Pipe, PipeTransform } from '@angular/core';

import { CurrencyConverter } from './currency-converter';
import { CountryCodesType } from './country-currency-codes';
import { map, Observable, take } from 'rxjs';
import { VisitorStore } from '../core-services/visitor/visitor.store';

@Pipe({
  name: 'currencyConverter',
})
export class CurrencyConverterPipe implements PipeTransform {
  constructor(private visitorStore: VisitorStore) {}

  public transform(amount: number, countryCode: CountryCodesType): Observable<string | null> {
    return this.visitorStore.loggedIn$.pipe(
      take(1),
      map((loggedIn: boolean) => CurrencyConverter.to(amount, countryCode, 'symbol', 'en-US', loggedIn))
    );
  }
}
